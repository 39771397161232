<template>
  <b-btn variant="primary" title="Download Template" @click="onClickcsvDownload">
    <i class="fa fa-download" aria-hidden="true"></i>
  </b-btn>
</template>
<style lang="scss" scoped>
  b-btn{
    position: relative;
    top: 1px;
  }
</style>
<script>
import axios from "axios"
export default {
  name: "CsvDownload",
  props: {
    propModuleFile: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      cvBtnCSvDownload: "Download Csv"
    }
  },
  methods: {
    async onClickcsvDownload () {
      try {
        let resp = await axios({ url: `/csv_templates/${this.propModuleFile}.csv`, method: "GET", responseType: "blob" })
        let downloadUrl = window.URL.createObjectURL(new Blob([ resp.data ]))
        let downloadLink = document.createElement("a")
        downloadLink.setAttribute("download", `${this.propModuleFile}.csv`)
        downloadLink.href = downloadUrl
        downloadLink.click()
      }
      catch (err) {
        console.error("Exception occurred at csvDownload() and Exception:", err.message)
      }
    }
  }
}
</script>
