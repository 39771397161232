/*eslint-disable */
import request from "../../Utils/curl";

let Csv = {
  /**
   * csv upload
   */
  async csvUpload (context, csvFile, module_prefix, org_id, module_obj) {
    try{
      let post_data= new FormData()
      post_data.append('csv', csvFile )
      post_data.append('module_prefix', module_prefix)
      post_data.append('module_obj', JSON.stringify(module_obj))

      if (org_id) {
        post_data.append('org_id', org_id)
      }

      return await request.curl(context, 'csv_insert', post_data)
      .then(async response=> {
        return response
      })
    }
    catch(err){
      console.error("Exception occurred at csvUpload() and Exception:",err.message)
    }
  },
  /**
   * csv Download File
   */
 
};

export{
  Csv
}
