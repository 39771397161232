<template>
  <div class="p-0 mr-2">
    <b-button class="Download_button" variant="primary" :title="propModulePrefix + ' CSV Bulk Upload Data'" @click="csvUploadOpen">
      <i class="fa fa-upload" aria-hidden="true"></i>
    </b-button>
    <b-modal
      v-model="showModelCsvUpload"
      size="sm"
      title="Bulk Upload">
      <template>
        <form action="#">
          <div class="form-row">
            <div class="col-md-12 mb-3 custom-file">
              <input
                type="file"
                class="custom-file-input"
                @change="onFileChange"
                required/>
              <label v-if="cvFile" class="custom-file-label" for="validatedCustomFile">{{cvFile.name}}</label>
              <label v-else class="custom-file-label" for="validatedCustomFile">{{ cvcsvChoose }}</label>
            </div>
          </div>
        </form>
      </template>
      <template #modal-footer="">
        <b-button variant="primary" size="sm" @click="removeFile">
          {{ cvbtnModalCsvRemove }}
        </b-button>
        <b-button  variant="primary" size="sm" @click="csvUpload">
          {{ cvbtnModalCsvUpload }}
        </b-button>
      </template>
    </b-modal>
    <b-toast
      v-model="showToast"
      name="Toast"
      :variant="toastVariant"
      :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </div>
</template>
<style>
  b-button{
    height: 40px;
  }
  .Download_button{
    top: 0px;
  }
  .Download_button i{
    padding: 0px 3px;
    position: relative;
    left: 3px;
  }
</style>

<script>
import { Csv } from "../../FackApi/api/csv"
import ApiResponse from "../../Utils/apiResponse.js"

export default {
  name: "CsvUpload",
  props: {
    propModulePrefix: {
      type: String,
      required: true
    },
    propOrgId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      cvBtnCsvUpload: "Bulk Upload",
      cvUpMessage: "CSV Bulk Upload",
      cvcsvChoose: "Choose File...",
      cvbtnModalCsvUpload: "Upload",
      showModelCsvUpload: false,
      cvbtnModalCsvRemove: "Remove",
      showToast: false,
      toastVariant: "default",
      toastTitle: "Csv Bulk Upload Response",
      toastMsg: null,
      cvFile: null,
      org_id: null,
      module_obj: {}
    }
  },
  mounted () {
    if (this.$route.params.org_id) {
      this.org_id = this.$route.params.org_id
      this.module_obj = { "module": "org", "module_id": this.org_id }
    }
    else if (this.$route.params.eventId) {
      this.module_obj = { "module": "event", "module_id": this.$route.params.eventId }
    }
  },
  methods: {
    /**
     * csv modal open
     */
    csvUploadOpen () {
      try {
        this.showModelCsvUpload = true
      }
      catch (err) {
        console.error(
          "Exception occurred at csvUpload() and Exception:",
          err.message
        )
      }
    },
    /**
     * onFileChange
     */
    onFileChange (e) {
      try {
        console.log(e.target.files)
        this.cvFile = e.target.files[0]
        if (!this.cvFile) {
          return null
        }
      }
      catch (err) {
        console.error("Exception occurred at onFileChange() and Exception:",
          err.message)
      }
    },
    /**
     * csv upload
     */
    async csvUpload () {
      try {
        let csvUploadResp = await Csv.csvUpload(this, this.cvFile, this.propModulePrefix, this.org_id, this.module_obj)
        if (csvUploadResp.resp_status) {
          await ApiResponse.responseMessageDisplay(this, csvUploadResp)
          this.showModelCsvUpload = false
        }
        else {
          this.toastMsg = csvUploadResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error(
          "Exception occurred at csvUpload() and Exception:",
          err.message
        )
      }
    },
    /**
     * removeFile
     */
    removeFile () {
      try {
        this.cvFile = null
      }
      catch (err) {
        console.error(
          "Exception occurred at removeFile() and Exception:",
          err.message
        )
      }
    }
  }
}
</script>

<style>
</style>
